export default () => {
    let tm, rTm, pTm
    let mediaQueryMatch = false
    const mediaQueryList = window.matchMedia('(min-width: 1200px)')
    let mobileMenuOpen = false

    try {
        mediaQueryList.addEventListener('change', onMediaQueryChange)
    } catch (err) {
        window.addEventListener('resize', () => {
            clearTimeout(pTm)
            pTm = setTimeout(() => {
                onMediaQueryChange(window.matchMedia('(min-width: 1200px)'))
            }, 25)
        })
    }

    onMediaQueryChange(mediaQueryList)

    document.querySelectorAll('button.dropdown-mobile-toggle').forEach(button => {
        button.addEventListener('click', event => {
            event.preventDefault()
            event.stopImmediatePropagation()
            const parent = button.parentElement.parentElement
            const dropdownMenu = parent.nextElementSibling
            if (parent.getAttribute('aria-expanded') === 'true') {
                parent.setAttribute('aria-expanded', 'false')
                dropdownMenu.classList.remove('show')
            } else {
                parent.setAttribute('aria-expanded', 'true')
                dropdownMenu.classList.add('show')
            }
        })
    })

    function onMediaQueryChange(queryList) {
        const oldValue = mediaQueryMatch
        mediaQueryMatch = queryList.matches === true
        if (oldValue !== mediaQueryMatch) {
            resetTimeouts()

            document.querySelectorAll('.main-header [aria-expanded]').forEach(el => {
                el.setAttribute('aria-expanded', oldValue ? 'true' : 'false')
            })
            document.querySelectorAll('.show').forEach(el => {
                if (!oldValue) {
                    el.classList.remove('show')
                }
            })
        }
        if (mediaQueryMatch) {
            document.documentElement.classList.remove('mobile-menu-open')
        } else if (mobileMenuOpen) {
            document.documentElement.classList.add('mobile-menu-open')
        }
    }

    document.querySelector('.navbar-toggler').addEventListener('click', () => {
        document.documentElement.classList.toggle('mobile-menu-open')
        mobileMenuOpen = document.documentElement.classList.contains('mobile-menu-open')
        resetDropdowns()
    })

    function attachListeners() {
        const mainHeader = document.querySelector('.main-flex .main-header')
        const mainContent = document.querySelector('.main-flex .main-content')

        mainHeader.addEventListener('click', () => mainHeader.classList.add('on-hover'))
        mainHeader.addEventListener('mouseenter', () => mainHeader.classList.add('on-hover'))

        mainContent.addEventListener('click', () => mainHeader.classList.remove('on-hover'))
        mainContent.addEventListener('mouseenter', () => mainHeader.classList.remove('on-hover'))

        document.querySelectorAll('.main-header a[data-bs-toggle]').forEach(el => {
            if (`${el.href}`.startsWith('javascript:void(0)')) {
                return
            }
            el.addEventListener('click', () => {
                window.location = el.href
            })
        })

        document.querySelectorAll('.navbar-aspena .dropdown.position-static > [data-bs-toggle="dropdown"]').forEach(el => {
            ['mouseenter', 'mouseover', 'focus'].forEach(event => {
                el.addEventListener(event, setDisplayTimeout)
            })
        })

        const navbar = document.querySelector('.navbar-aspena')
        navbar.addEventListener('mouseleave', () => {
            setResetDropdownsTimeout()
            navbar.querySelectorAll('.mega-dropdown-menu-column .nav-column-toggler').forEach(el => el.classList.remove('active'))
        })

        document.querySelectorAll('.navbar-aspena .dropdown-menu').forEach(menu => {
            menu.addEventListener('mouseenter', resetTimeouts)
        })

        mainContent.addEventListener('click', setResetDropdownsTimeout)
        mainContent.addEventListener('mouseenter', setResetDropdownsTimeout)

        document.querySelectorAll('.navbar-aspena .top-level-link:not(.dropdown-toggle)').forEach(link => {
            link.addEventListener('mouseenter', setResetDropdownsTimeout)
        })
    }

    function resetTimeouts() {
        clearTimeout(tm)
        clearTimeout(rTm)
    }

    function setResetDropdownsTimeout() {
        if (mediaQueryMatch) {
            clearTimeout(rTm)
            rTm = setTimeout(resetDropdowns, 10)
        }
    }

    function resetDropdowns() {
        document.querySelectorAll('.navbar-aspena [data-bs-toggle="dropdown"][aria-expanded="true"]').forEach(el => {
            el.setAttribute('aria-expanded', 'false')
        })
        document.querySelectorAll('.navbar-aspena .dropdown-menu.show').forEach(el => {
            el.classList.remove('show')
        })
    }

    function setDisplayTimeout(event) {
        if (mediaQueryMatch) {
            if (document.querySelector('.navbar-aspena .dropdown-menu.show')) {
                tm = setTimeout(() => displayDropdown(event), 10)
            } else {
                displayDropdown(event)
            }
        }
    }

    function displayDropdown(event) {
        if (mediaQueryMatch) {
            if (event.target.getAttribute('aria-expanded') === 'false') {
                resetDropdowns()
                setTimeout(() => {
                    event.target.setAttribute('aria-expanded', 'true')
                    event.target.parentElement.querySelector('.dropdown-menu').classList.add('show')
                }, 0)
            }
        }
    }

    attachListeners()
}
